import clsx from "clsx"
import Link from "next/link"

const HOVER_STYLE = "hover:border-gray-500 dark:hover:border-gray-100"

export default function SiteHeading() {
  return (
    <div className="container flex justify-center my-8">
      <Link href="/">
        <a>
          <div className={clsx("px-4 border-l border-r border-gray-400", HOVER_STYLE)}>
            <h1 className="text-4xl font-bold text-center">
              黑鴨鴨打油
            </h1>
            軌域湮滅虛無遞迴薄切煙燻鴨鴨
          </div>
        </a>
      </Link>
    </div>
  )
}
