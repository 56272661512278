import type { FC } from 'react'
import Head from 'next/head'
import clsx from 'clsx';
import SiteHeading from 'components/SiteHeading'

const AppHead = () => (
  <Head>
    <title>黑鴨鴨打油</title>
    <meta name="description" content="Duck Souls' blog" />
    <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
    <link rel="alternate icon" type="image/png" href="/favicon.png" />
    <link rel="mask-icon" href="/mask-icon.svg" color="#FCBB42" />
  </Head>
)

interface Props {
  className?: string
}
const AppLayout: FC<Props> = ({ children, className }) => {
  return (
    <div className={clsx("max-w-4xl m-auto px-4 py-2", className)}>
      <AppHead />
      <SiteHeading />
      {children}
    </div>
  )
}
export default AppLayout
