import Image from 'next/image'
import { dateFormat } from 'lib/format'

export interface Props {
  title: string;
  date: string;
  slug: string;
  image?: string;
}

export default function PostItem ({title, date, slug, image}: Props){
  const src = image && `/post-images/${slug}/${image}`
  return (
    <div className=" border-gray-400 hover:border-gray-200">
      <time className="text-sm text-gray-500 dark:text-gray-400" dateTime={date}>
        {dateFormat(date)}
      </time>
      <h2 className="text-xl md:text-2xl">{title}</h2>
      {src && (
        <div className="flex py-4">
          <Image alt={title} src={src} width="960" height="720" />
        </div>
      )}
    </div>
  )
}
