import type { GetServerSideProps } from 'next'
import Link from 'next/link'
import Head from 'next/head'
import AppLayout from 'components/AppLayout'
import Footer from 'components/Footer'
import PostItem from 'components/PostItem'
import { getAllPostsFromJson } from '../lib/metaFiles'
import { getOrigin } from '../lib/utils'

export const getServerSideProps: GetServerSideProps = async (context) => {
  const posts = await getAllPostsFromJson(context.req)

  const props: Props = { posts, origin: getOrigin(context.req) }

  return { props }
}

interface Props {
  posts: {
    title: string
    slug: string
    date: string
    image?: string
  }[],
  origin: string;
}

export default function Home({ posts, origin }: Props) {
  const links = posts.sort((a, b) => -(a.date.localeCompare(b.date))).map(post => (
    <li key={post.slug} className="mt-4 first:mt-0">
      <Link href={`/posts/${post.slug}`}>
        <a>
          <PostItem slug={post.slug} title={post.title} date={post.date} image={post.image} />
        </a>
      </Link>
    </li>
  ))

  const firstImagePost = posts.find(post => post.image)

  return (
    <AppLayout>
      <Head>
        <meta property="og:url" content={origin} />
        <meta property="og:title" content="黑鴨鴨打油" />
        <meta property="og:type" content='website' />
        <meta property="og:site_name" content="黑鴨鴨打油" />
        {firstImagePost && firstImagePost.image && (
          <meta property="og:image" content={`${origin}/post-images/${firstImagePost.slug}/${firstImagePost.image}`} />
        )}
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:site" content="@darkducksouls" />
      </Head>

      <main className="mb-8">
        <ul>{links}</ul>
      </main>
      <Footer />
    </AppLayout>
  )
}
