import type { FC } from 'react'
import Link from "next/link"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRssSquare, faAddressCard } from '@fortawesome/free-solid-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'

 const Footer: FC = ({ children }) => {
  return (
    <footer className="text-center border-t py-8 border-zinc-400 text-gray-500 dark:text-zinc-200">
      {children && (
        <div className="mb-6">
          {children}
        </div>
      )}

      <div className="flex justify-center items-center h-5">
        <a
          href="https://twitter.com/darkducksouls"
          className="flex h-full items-center dark:hover:text-zinc-100 hover:text-black"
        >
          <FontAwesomeIcon className="h-full mr-2 dark:text-white text-[#1DA1F2]" icon={faTwitter} />
          Twitter
        </a>
        <span className="mx-3">•</span>
        <Link href="/feed.xml">
          <a className="flex h-full items-center dark:hover:text-zinc-100 hover:text-black">
            <FontAwesomeIcon className="h-full mr-2" icon={faRssSquare} />
            Subscribe
          </a>
        </Link>
        <span className="mx-3">•</span>
        <a
          href="https://www.ducksouls.net"
          className="flex h-full items-center dark:hover:text-zinc-100 hover:text-black"
        >
          <FontAwesomeIcon className="h-full mr-2" icon={faAddressCard} />
          Profile
        </a>
      </div>
    </footer>
  )
}
export default Footer
